import styled from "styled-components"
import { orangeGradient } from "./helpers"

export const IconButtonBase = styled.button`
  appearance: none;
  border: none;
  outline: none;
  margin: 0;
  display: grid;
  box-shadow: ${({ theme }) => theme.shadows.small};
  cursor: pointer;
  border-radius: 10em;
  color: ${({ theme }) => theme.colors.gray500};
  background-color: rgb(255, 255, 255, 0.5);
  padding: 0 ${({ theme }) => theme.spacings.fluid.tiny}vw;

  transition: transform 50ms ease-in-out, background-color 120ms ease-in-out,
    filter 50ms ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: translateY(2px);
    box-shadow: none;
  }

  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
    filter: grayscale(1);
  }

  svg {
    fill: currentColor;
    transition: fill 150ms ease-in-out;
  }
`

export const MainButton = styled(IconButtonBase)`
  ${orangeGradient()};
  display: flex;
  place-items: center;
  padding-left: 1vmax;
  padding-top: 1vmax;
  padding-right: 1vmax;
  padding-bottom: 1vmax;
  border-radius: 50%;
  width: auto;
  height: auto;
  line-height: normal;
  align-self: center;

  svg {
    width: calc(22px + (100vmin - 375px) * 0.019);
    height: calc(22px + (100vmin - 375px) * 0.019);
    fill: ${({ theme }) => theme.colors.primary.shade};
  }
`

export const BackButton = styled(IconButtonBase)`
  background-color: transparent;
  box-shadow: none;
  border: 2px solid;
  padding-top: 0;
  padding-bottom: 0;
  color: ${({ theme }) => theme.colors.cta.main};
  position: absolute;
  top: ${({ theme }) => theme.spacings.fluid.small}vh;
  left: ${({ theme }) => theme.spacings.fluid.small}vw;

  svg {
    transform: rotate(180deg);
  }
`

export const SmallIconButton = styled(IconButtonBase)`
  ${orangeGradient()};
  padding: ${({ theme }) => theme.spacings.fluid.tiny}vw;
  color: ${({ theme }) => theme.colors.primary.shade};
`

export const SpaceButton = styled(SmallIconButton)`
  color: white;
  text-align: center;
  font-size: 2.5vmin;
  margin: 1vw;
  padding: 1vw;
  padding-right: 2.5vw;
  padding-left: 2.5vw;
`
