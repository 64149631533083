import React from "react"

export function GirlOneHead(props) {
  return (
    <svg width={300} height={300} viewBox="0 0 300 300" fill="none" {...props}>
      <path
        d="M190.264 260c22.899 0 41.463-18.563 41.463-41.462 0-22.899-18.564-41.462-41.463-41.462s-41.462 18.563-41.462 41.462c0 22.899 18.563 41.462 41.462 41.462z"
        fill="#00273D"
      />
      <path
        d="M150.425 225.712c-41.598 0-75.438-33.841-75.438-75.438v-34.836C74.987 73.84 108.827 40 150.425 40c41.597 0 75.437 33.84 75.437 75.438v34.836c0 41.597-33.84 75.438-75.437 75.438z"
        fill="#00273D"
      />
      <path
        d="M150.425 220.025c-38.359 0-69.744-31.385-69.744-69.744v-34.836c0-38.36 31.385-69.744 69.744-69.744 38.359 0 69.744 31.384 69.744 69.744v34.836c0 38.359-31.385 69.744-69.744 69.744z"
        fill="#F8CBB4"
      />
      <path
        d="M225.862 130.909v-14.055c0-43.477-36.096-79.06-79.515-76.74-39.712 2.12-71.36 35.1-71.36 75.331v16.432c0 .121 58.6 36.744 90.952-32.538 13.252 21.927 33.463 31.876 59.589 31.613.149.135.334.157.334-.043z"
        fill="#00273D"
      />
      <path
        d="M80.68 127.543c-1.06-8.682.427-17.756 3.843-26.097 3.445-8.348 8.903-15.892 15.621-22.076 6.719-6.192 14.718-11.017 23.308-14.255 8.597-3.245 17.792-4.868 26.979-4.925l6.882.342c2.285.206 4.555.605 6.832.91 4.512.876 8.975 2.022 13.273 3.666 8.59 3.245 16.589 8.056 23.307 14.255 6.719 6.184 12.177 13.735 15.622 22.083 3.409 8.341 4.896 17.415 3.828 26.097-1.046-8.661-3.722-16.874-7.992-24.055-4.213-7.202-9.807-13.443-16.276-18.475-12.938-10.12-29.214-15.33-45.476-15.386-16.261.043-32.545 5.252-45.483 15.372-6.469 5.032-12.063 11.273-16.276 18.482-4.277 7.188-6.96 15.401-7.992 24.062z"
        fill="#F59F26"
      />
      <path
        d="M88.074 174.092c7.01-7.009 7.01-18.374 0-25.383s-18.373-7.009-25.383 0c-7.009 7.009-7.009 18.373 0 25.383 7.01 7.009 18.374 7.009 25.383 0zM238.135 174.088c7.009-7.009 7.009-18.373 0-25.383-7.01-7.009-18.374-7.009-25.383 0-7.009 7.01-7.009 18.374 0 25.383s18.373 7.009 25.383 0z"
        fill="#F8CBB4"
      />
      <path
        d="M125.666 174.941c0 13.671 11.087 24.759 24.759 24.759 13.671 0 24.759-11.088 24.759-24.759h-49.518z"
        fill="#FFFEF9"
      />
      <path
        d="M114.058 165.803a5.015 5.015 0 01-5.003-5.003v-6.356a5.015 5.015 0 015.003-5.003 5.015 5.015 0 015.003 5.003v6.356a5.015 5.015 0 01-5.003 5.003zM186.799 165.803a5.015 5.015 0 01-5.004-5.003v-6.356a5.015 5.015 0 015.004-5.003 5.015 5.015 0 015.003 5.003v6.356a5.015 5.015 0 01-5.003 5.003z"
        fill="#00273D"
      />
    </svg>
  )
}

export function GirlOneBody(props) {
  return (
    <svg width={300} height={600} viewBox="0 0 300 600" fill="none" {...props}>
      <path
        d="M148 555.011c55.228 0 100-4.477 100-10s-44.772-10-100-10-100 4.477-100 10 44.772 10 100 10z"
        fill="#F4FAFB"
      />
      <path
        d="M128.192 542.505c-6.44 0-11.665-5.224-11.665-11.664V372.533h23.329v158.308c0 6.448-5.225 11.664-11.664 11.664zM172.75 542.505c-6.44 0-11.665-5.224-11.665-11.664V372.533h23.33v158.308c0 6.448-5.225 11.664-11.665 11.664z"
        fill="#F8CBB4"
      />
      <path
        d="M128.192 530.207c-6.44 0-11.665-5.225-11.665-11.665v12.299c0 6.44 5.225 11.664 11.665 11.664 6.439 0 11.664-5.224 11.664-11.664v-12.299c0 6.44-5.225 11.665-11.664 11.665zM172.751 530.207c-6.44 0-11.665-5.225-11.665-11.665v12.299c0 6.44 5.225 11.664 11.665 11.664 6.44 0 11.664-5.224 11.664-11.664v-12.299c0 6.44-5.224 11.665-11.664 11.665zM199.057 318.298c27.926 0 50.564-22.639 50.564-50.565s-22.638-50.564-50.564-50.564-50.565 22.638-50.565 50.564 22.639 50.565 50.565 50.565z"
        fill="#00273D"
      />
      <path
        d="M150.471 276.482c-50.73 0-91.998-41.27-91.998-91.999v-42.484C58.473 91.269 99.742 50 150.47 50c50.73 0 91.999 41.27 91.999 91.999v42.484c0 50.729-41.269 91.999-91.999 91.999z"
        fill="#00273D"
      />
      <path
        d="M150.471 269.547c-46.78 0-85.055-38.275-85.055-85.055v-42.485c0-46.78 38.275-85.055 85.055-85.055 46.781 0 85.056 38.275 85.056 85.055v42.485c0 46.78-38.275 85.055-85.056 85.055z"
        fill="#F8CBB4"
      />
      <path
        d="M242.47 160.867v-17.141c0-53.021-44.02-96.417-96.972-93.587-48.43 2.586-87.025 42.805-87.025 91.868v20.04c0 .148 71.464 44.811 110.919-39.681 16.16 26.741 40.809 38.874 72.67 38.553.182.165.408.191.408-.052z"
        fill="#00273D"
      />
      <path
        d="M65.416 156.762c-1.294-10.589.52-21.655 4.686-31.826 4.201-10.181 10.858-19.381 19.051-26.923 8.193-7.55 17.948-13.435 28.424-17.384 10.484-3.958 21.698-5.937 32.903-6.006l8.392.416c2.786.252 5.555.738 8.332 1.111 5.503 1.068 10.945 2.465 16.187 4.47 10.476 3.958 20.231 9.825 28.424 17.384 8.193 7.542 14.85 16.751 19.051 26.932 4.157 10.171 5.971 21.237 4.669 31.826-1.276-10.563-4.539-20.578-9.747-29.336-5.138-8.783-11.959-16.394-19.849-22.531-15.778-12.341-35.628-18.694-55.459-18.764-19.832.052-39.69 6.405-55.469 18.747-7.889 6.136-14.71 13.748-19.849 22.54-5.216 8.766-8.488 18.781-9.746 29.344z"
        fill="#F59F26"
      />
      <path
        d="M74.433 213.53c8.548-8.548 8.548-22.408 0-30.956-8.548-8.548-22.408-8.548-30.956 0-8.547 8.548-8.547 22.408 0 30.956 8.549 8.548 22.408 8.548 30.956 0zM257.437 213.525c8.548-8.548 8.548-22.407 0-30.955-8.548-8.548-22.407-8.548-30.955 0-8.548 8.548-8.548 22.407 0 30.955 8.548 8.548 22.407 8.548 30.955 0z"
        fill="#F8CBB4"
      />
      <path
        d="M120.276 214.565c0 16.672 13.522 30.194 30.195 30.194 16.673 0 30.195-13.522 30.195-30.194h-60.39z"
        fill="#FFFEF9"
      />
      <path
        d="M186.599 407.768c7.968-1.512 13.202-9.197 11.691-17.165-1.512-7.969-9.197-13.203-17.166-11.691-7.968 1.512-13.202 9.197-11.69 17.165 1.511 7.969 9.197 13.203 17.165 11.691z"
        fill="#F8CBB4"
      />
      <path
        d="M183.13 393.485a8.682 8.682 0 01-8.679-8.679v-90.48a8.682 8.682 0 018.679-8.679 8.682 8.682 0 018.679 8.679v90.48a8.677 8.677 0 01-8.679 8.679zM116.666 376.587a8.67 8.67 0 01-6.77-3.246l-37.485-46.746c-3.003-3.741-2.395-9.2 1.337-12.203 3.74-3.003 9.2-2.395 12.203 1.337l22.036 27.478v-49.706c0-4.79 3.888-8.679 8.679-8.679s8.679 3.889 8.679 8.679v74.398a8.673 8.673 0 01-5.798 8.184 8.335 8.335 0 01-2.881.504z"
        fill="#F8CBB4"
      />
      <path
        d="M59.618 311.537c-4.495 6.752-2.673 15.865 4.08 20.361 6.752 4.496 15.865 2.673 20.361-4.079 4.496-6.753 2.673-15.866-4.08-20.362-6.751-4.495-15.856-2.664-20.36 4.08z"
        fill="#F8CBB4"
      />
      <path
        d="M83.321 324.885a5.212 5.212 0 01-5.207-5.207v-14.685a5.212 5.212 0 015.207-5.208 5.212 5.212 0 015.208 5.208v14.685a5.2 5.2 0 01-5.208 5.207z"
        fill="#F8CBB4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.414 347.798h-67.879v24.744h-.008v136.514h23.33V392.035h21.229v117.021h23.33V372.542h-.002v-24.744z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M184.415 347.798h-67.879v-53.88c0-5.459 4.426-9.877 9.877-9.877h48.117c5.459 0 9.877 4.426 9.877 9.877v53.88h.008z"
        fill="url(#prefix__paint1_linear)"
      />
      <path
        d="M150.472 303.534c-7.985 0-14.46-6.474-14.46-14.459v-27.443h28.919v27.443c0 7.993-6.475 14.459-14.459 14.459z"
        fill="#F8CBB4"
      />
      <path
        d="M106.121 203.421c-3.358 0-6.101-2.743-6.101-6.102v-7.75c0-3.359 2.743-6.102 6.101-6.102 3.359 0 6.102 2.743 6.102 6.102v7.75c0 3.359-2.743 6.102-6.102 6.102zM194.83 203.421c-3.358 0-6.101-2.743-6.101-6.102v-7.75c0-3.359 2.743-6.102 6.101-6.102 3.359 0 6.102 2.743 6.102 6.102v7.75c0 3.359-2.743 6.102-6.102 6.102z"
        fill="#00273D"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={117}
          y1={373}
          x2={184}
          y2={462}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#19B1D2" />
          <stop offset={1} stopColor="#00273D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={117}
          y1={284}
          x2={184}
          y2={348}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F59F26" />
          <stop offset={1} stopColor="#E15814" />
        </linearGradient>
      </defs>
    </svg>
  )
}
