import React from "react"
import PropTypes from "prop-types"

import Layout from "./layout"

const Content = ({ height, children }) => (
  <Layout layoutHeight={height} children={children} />
)

Content.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.number,
}

export default Content
