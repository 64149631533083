import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"

import { theme } from "../styles/theme"
import { GlobalStyle } from "../styles/globalStyle"

const Navigation = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />

    <nav>{children}</nav>
  </ThemeProvider>
)

Navigation.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Navigation
