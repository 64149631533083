import React from "react"

export function BoyOneHead(props) {
  return (
    <svg
      width={300}
      height={300}
      viewBox="0 0 300 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M141.739 211.654c-20.041 0-38.316-5.2-51.466-14.646-14.613-10.493-22.335-25.374-22.335-43.033v-29.466c0-36.17 32.419-64.509 73.801-64.509 41.382 0 73.8 28.333 73.8 64.509v29.466c0 17.659-7.722 32.54-22.334 43.033-13.15 9.446-31.426 14.646-51.466 14.646z"
        fill="#F59F26"
      />
      <path
        d="M75.989 185.34c7.725 0 13.988-6.262 13.988-13.988s-6.263-13.989-13.988-13.989c-7.726 0-13.989 6.263-13.989 13.989 0 7.726 6.263 13.988 13.989 13.988zM208.085 185.34c7.726 0 13.989-6.262 13.989-13.988s-6.263-13.989-13.989-13.989c-7.725 0-13.988 6.263-13.988 13.989 0 7.726 6.263 13.988 13.988 13.988z"
        fill="#F8CBB4"
      />
      <path
        d="M141.739 240c-36.163 0-65.75-20.08-65.75-56.243v-32.841c0-36.163 29.587-63.851 65.75-63.851 36.162 0 65.749 27.688 65.749 63.851v32.841c0 36.169-29.587 56.243-65.749 56.243z"
        fill="#F8CBB4"
      />
      <path
        d="M141.738 207.796c-11.969 0-23.495-3.905-32.445-11.003-4.355-3.449-5.086-9.782-1.637-14.136 3.455-4.354 9.782-5.086 14.136-1.637 5.407 4.287 12.492 6.648 19.946 6.648 7.34 0 14.338-2.301 19.718-6.474 4.395-3.408 10.715-2.61 14.123 1.778 3.408 4.388 2.61 10.715-1.778 14.123-8.896 6.897-20.282 10.701-32.063 10.701z"
        fill="#FFFEF9"
      />
      <path
        d="M108.769 164.992c-2.73 0-4.964-2.234-4.964-4.965v-4.026a4.979 4.979 0 014.964-4.964c2.731 0 4.965 2.234 4.965 4.964v4.019c0 2.731-2.234 4.972-4.965 4.972zM174.727 164.992c-2.73 0-4.964-2.234-4.964-4.965v-4.026a4.979 4.979 0 014.964-4.964c2.731 0 4.965 2.234 4.965 4.964v4.019c0 2.731-2.234 4.972-4.965 4.972z"
        fill="#00273D"
      />
      <path
        d="M217.209 145.455a35.979 35.979 0 01-4.361.275h-73.733c-9.702 0-18.538-3.972-24.938-10.379-6.401-6.401-10.379-15.237-10.379-24.938 0-19.417 15.894-35.317 35.317-35.317h46.991c18.423 15.927 30.52 41.093 31.103 70.359z"
        fill="#F59F26"
      />
      <path
        d="M160.853 103.395h42.107c7.783 0 14.15-6.367 14.15-14.15 0-7.782-6.367-14.15-14.15-14.15h-42.107c-7.782 0-14.149 6.368-14.149 14.15.006 7.783 6.367 14.15 14.149 14.15zM154.332 145.716h62.898c11.64 0 21.161-9.52 21.161-21.16 0-11.641-9.521-21.161-21.161-21.161h-62.898c-11.641 0-21.161 9.52-21.161 21.161 0 11.64 9.527 21.16 21.161 21.16z"
        fill="#F59F26"
      />
    </svg>
  )
}

export function BoyOneBody(props) {
  return (
    <svg
      width={300}
      height={600}
      viewBox="0 0 300 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M144 549c55.228 0 100-4.477 100-10s-44.772-10-100-10-100 4.477-100 10 44.772 10 100 10z"
        fill="#F4FAFB"
      />
      <path
        d="M139.289 391.368H116.36v147.994h22.929V391.368z"
        fill="#F8CBB4"
      />
      <path
        d="M139.289 539.353H110.92a3.769 3.769 0 01-3.771-3.771v-1.955a3.77 3.77 0 013.771-3.771h28.369v9.497z"
        fill="#00273D"
      />
      <path
        d="M150.899 539.355h22.928V391.362h-22.928v147.993z"
        fill="#F8CBB4"
      />
      <path
        d="M150.896 539.353h28.368a3.768 3.768 0 003.771-3.771v-1.955a3.769 3.769 0 00-3.771-3.771h-28.368v9.497z"
        fill="#00273D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M177.63 371.199v13.267c0 4.104-1.417 7.878-3.788 10.859v94.763h-22.929V401.92h-11.642v88.168h-22.928v-93.403a17.394 17.394 0 01-4.99-12.219v-13.267h66.277zm-26.732 158.659h22.929v-15.066h-22.929v15.066zm-34.538-15.059h22.929v15.066H116.36v-15.066z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M212.686 314.651h17.315c.069-2.793-.009-5.518-.078-8.069-.052-2.007-.112-4.031-.112-6.063h-17.298c0 2.188.06 4.376.121 6.539.078 2.932.104 5.431.052 7.593z"
        fill="#F9C4A1"
      />
      <path
        d="M226.272 305.881a2.88 2.88 0 003.399-2.248l3.201-15.681a2.88 2.88 0 00-2.249-3.399 2.88 2.88 0 00-3.399 2.249l-3.2 15.68a2.902 2.902 0 002.248 3.399z"
        fill="#F8CBB4"
      />
      <path
        d="M222.415 304.497a2.89 2.89 0 002.88-2.88v-18.881a2.89 2.89 0 00-2.88-2.88 2.891 2.891 0 00-2.88 2.88v18.881a2.88 2.88 0 002.88 2.88z"
        fill="#F8CBB4"
      />
      <path
        d="M211.189 305.985a9.687 9.687 0 01-5.146-1.488 9.703 9.703 0 01-4.325-6.071 2.604 2.604 0 011.946-3.114c1.402-.328 2.785.554 3.114 1.946a4.51 4.51 0 002.015 2.837 4.486 4.486 0 003.434.58 4.512 4.512 0 002.837-2.016 4.509 4.509 0 00.579-3.425 4.565 4.565 0 00-5.449-3.416c-1.392.329-2.785-.554-3.105-1.946a2.604 2.604 0 011.946-3.114c5.233-1.202 10.466 2.076 11.668 7.309a9.654 9.654 0 01-1.237 7.343 9.664 9.664 0 01-6.071 4.316 9.61 9.61 0 01-2.206.259zM96.105 404c1.545-7.934-3.634-15.618-11.567-17.163-7.934-1.545-15.618 3.633-17.163 11.567-1.546 7.933 3.633 15.617 11.567 17.163 7.933 1.545 15.617-3.634 17.163-11.567z"
        fill="#F8CBB4"
      />
      <path d="M89.22 362.558H71.92v38.653H89.22v-38.653z" fill="#F8CBB4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M177.63 338.448v32.751h-66.268v-53.376c-13.577 7.327-22.143 23.719-22.143 44.735v14.315H71.921v-14.315c0-38.367 23.725-66.217 56.409-66.217v.082c.622-.071 1.23-.108 1.817-.108h28.689c6.715.008 16.093 4.771 18.311 11.1 1.358 3.334 12.523 29.3 27.935 21.274 4.887-2.543 7.508-4.454 7.646-14.747l17.298.234c-.095 7.178-.32 23.992-19.045 30.782a33.163 33.163 0 01-11.218 1.946c-8.277 0-16.343-3.052-22.133-8.456z"
        fill="url(#prefix__paint1_linear)"
      />
      <path
        d="M144.496 317.376s-18.007-8.061-18.007-18.007v-34.908h36.014v34.908c0 9.946-18.007 18.007-18.007 18.007z"
        fill="#F8CBB4"
      />
      <path
        d="M144.496 245.502c-25.835 0-49.395-6.703-66.347-18.88-18.837-13.527-28.793-32.711-28.793-55.475V133.16c0-46.626 41.792-83.16 95.14-83.16 53.347 0 95.139 36.525 95.139 83.16v37.987c0 22.764-9.955 41.948-28.793 55.475-16.952 12.177-40.512 18.88-66.346 18.88z"
        fill="#F59F26"
      />
      <path
        d="M59.735 211.581c9.96 0 18.033-8.074 18.033-18.033 0-9.96-8.074-18.033-18.033-18.033-9.96 0-18.033 8.073-18.033 18.033 0 9.959 8.073 18.033 18.033 18.033zM230.025 211.581c9.96 0 18.034-8.074 18.034-18.033 0-9.96-8.074-18.033-18.034-18.033-9.959 0-18.033 8.073-18.033 18.033 0 9.959 8.074 18.033 18.033 18.033z"
        fill="#F8CBB4"
      />
      <path
        d="M144.495 282.045c-46.618 0-84.76-25.887-84.76-72.505v-42.337c0-46.618 38.142-82.313 84.76-82.313 46.619 0 84.761 35.695 84.761 82.313v42.337c0 46.627-38.142 72.505-84.761 72.505z"
        fill="#F8CBB4"
      />
      <path
        d="M144.495 240.529c-15.43 0-30.289-5.033-41.826-14.184-5.614-4.446-6.556-12.61-2.111-18.224 4.455-5.613 12.611-6.556 18.224-2.11 6.971 5.527 16.104 8.571 25.713 8.571 9.462 0 18.483-2.966 25.42-8.346 5.665-4.394 13.812-3.365 18.206 2.292 4.394 5.656 3.365 13.812-2.292 18.206-11.469 8.891-26.146 13.795-41.334 13.795z"
        fill="#FFFEF9"
      />
      <path
        d="M101.994 185.348c-3.52 0-6.4-2.88-6.4-6.4v-5.189c0-3.52 2.88-6.4 6.4-6.4 3.52 0 6.4 2.88 6.4 6.4v5.181c0 3.52-2.88 6.408-6.4 6.408zM187.022 185.348c-3.52 0-6.4-2.88-6.4-6.4v-5.189c0-3.52 2.88-6.4 6.4-6.4 3.521 0 6.401 2.88 6.401 6.4v5.181c0 3.52-2.88 6.408-6.401 6.408z"
        fill="#00273D"
      />
      <path
        d="M241.788 160.163c-1.842.224-3.71.354-5.622.354h-95.053c-12.506 0-23.897-5.12-32.148-13.38-8.251-8.251-13.38-19.642-13.38-32.148 0-25.03 20.489-45.529 45.528-45.529h60.578c23.75 20.533 39.345 52.976 40.097 90.703z"
        fill="#F59F26"
      />
      <path
        d="M169.137 105.942h54.281c10.033 0 18.241-8.208 18.241-18.24 0-10.034-8.208-18.242-18.241-18.242h-54.281c-10.033 0-18.241 8.208-18.241 18.241.009 10.033 8.208 18.241 18.241 18.241zM160.73 160.5h81.084c15.006 0 27.279-12.273 27.279-27.279s-12.273-27.279-27.279-27.279H160.73c-15.006 0-27.279 12.273-27.279 27.279S145.732 160.5 160.73 160.5z"
        fill="#F59F26"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={111}
          y1={371}
          x2={169}
          y2={492}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#19B1D2" />
          <stop offset={1} stopColor="#00273D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={93}
          y1={312.5}
          x2={175}
          y2={377}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F59F26" />
          <stop offset={1} stopColor="#E15814" />
        </linearGradient>
      </defs>
    </svg>
  )
}
