import React from "react"
import PropTypes from "prop-types"

import CurrentUserDisplay from "./CurrentUserDisplay.js"
import { useStudentStore } from "../../../store.js"

const CurrentUser = ({ pos = "corner" }) => {
  const student = useStudentStore((store) => store.student)

  return (
    <CurrentUserDisplay
      pos={pos}
      alias={student?.alias}
      gender={student?.gender}
    />
  )
}

CurrentUser.propTypes = {
  pos: PropTypes.oneOf(["corner", "inline"]),
}

export default CurrentUser
