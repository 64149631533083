// Basic libraries
import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import { ToastContainer } from "react-toastify"

// Used components
import Navigation from "../../components/navigation"
import Content from "../../components/content"
import Seo from "../../components/seo"
import CurrentUser from "../../components/CurrentUser"

// Images & Styling
import { BackButton } from "../../styles/buttons"
import ArrowIcon from "../../lib/icons/Arrow"
import { GameSelectionLayout } from "../../styles/common"

import { updateCurrentStudentData } from "../../services/helpers"

// Browser check
const isBrowser = typeof window !== "undefined"

// component
class SelectionPage extends Component {
  constructor() {
    super()
    this.state = {
      window: {
        width: 0,
        height: 0,
      },
    }
    this.handleResize = this.handleResize.bind(this)
  }
  // Fehlerschwerpunkt: 3 größten Zahlen in mistakeprognosis --> auf Fehlerkategorie mappen...

  handleResize = () => {
    this.setState({
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    })
  }

  checkStudentData = async () => {
    try {
      const updateSuccessResult = await updateCurrentStudentData()
      console.log("update success:", updateSuccessResult)

      if (!updateSuccessResult.success && isBrowser) {
        navigate("/gamesTest", { replace: true })
      }
    } catch (error) {
      console.error(error)
      if (isBrowser) navigate("/gamesTest", { replace: true })
    }
  }

  componentWillMount() {
    // jwt.checkPermission()
    this.checkStudentData()
  }

  componentDidMount() {
    if (isBrowser) {
      this.setState(
        {
          window: {
            width: window.innerWidth,
            height: window.innerHeight,
          },
        },
        () => window.addEventListener("resize", this.handleResize)
      )
    }
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize)
  }

  render() {
    const windowHeight = this.state.window.height

    return (
      <div>
        <ToastContainer theme="colored" autoClose={2000} />
        <Seo title="Spiele-Auswahl" />
        <Navigation>
          <BackButton as={Link} to="/student/profile" alt="Zurück zum Profil">
            <ArrowIcon />
          </BackButton>
          <CurrentUser />
        </Navigation>
        <Content height={windowHeight}>
          <GameSelectionLayout></GameSelectionLayout>
        </Content>
      </div>
    )
  }
}
// component-end

// PropTypes
SelectionPage.propTypes = {
  window: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
}

export default SelectionPage
