import React from "react"
import PropTypes from "prop-types"

import { StyledCurrentUser } from "./CurrentUserStyles"
import { GirlOneHead } from "../../lib/avatars/GirlOne"
import { BoyOneHead } from "../../lib/avatars/BoyOne"

const CurrentUserDisplay = ({ pos, alias, gender }) => {
  return alias ? (
    <StyledCurrentUser data-pos={pos} to="/student/profile/edit">
      {gender === "female" && <GirlOneHead />}
      {gender === "male" && <BoyOneHead />}
      <span className="username">{alias}</span>
    </StyledCurrentUser>
  ) : (
    <StyledCurrentUser data-pos={pos} to="/student/profile/edit">
      <span className="username">Profil anlegen</span>
    </StyledCurrentUser>
  )
}

CurrentUserDisplay.propTypes = {
  pos: PropTypes.oneOf(["corner", "inline"]),
  alias: PropTypes.string,
  gender: PropTypes.string,
}

export default CurrentUserDisplay
