import { Link } from "gatsby"
import styled from "styled-components"

export const StyledCurrentUser = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  z-index: 10;

  &[data-pos="corner"] {
    position: absolute;
    top: ${({ theme }) => theme.spacings.fluid.tiny}vh;
    right: ${({ theme }) => theme.spacings.fluid.small}vw;
  }

  span {
    font-size: clamp(18px, 3.5vw, 24px);
    color: ${({ theme }) => theme.colors.cta.main};
  }

  svg {
    --size: 50px;

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
      --size: 75px;
    }

    width: var(--size);
    height: var(--size);
  }
`
